import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import layoutStyles from '../styles/layout.scss';


const Layout = ({ children, pageTitle, metadata }) => (
  <React.Fragment>
    <Helmet title={pageTitle} meta={metadata}>
      <html lang="en" />
    </Helmet>

    {children}
  </React.Fragment>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
